/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.requested-provider-notice {
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #99bce1;
  border-radius: 9px;
  color: #5096e1;
  font-size: 12px;
}
.requested-provider-notice .icon {
  margin-right: 5px;
}
.provider-profile-page .requested-provider-notice {
  margin-bottom: 20px;
}
