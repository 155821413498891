@import 'styles/imports.scss';

.ui-components__card {
  border: solid 1px $border-color;
  border-radius: $default-border-radius;
  background: $white;

  &__head {
    display: block;
    position: relative;
    height: 0;
    padding-top: calc(80 / 180) * 100%;
    overflow: hidden;
    border-top-left-radius: $default-border-radius - 1px;
    border-top-right-radius: $default-border-radius - 1px;

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &__body {
    padding: $space-1;

    &__title,
    &__subtitle {
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__title {
      font-size: $font-size-large;
      font-weight: 700;
    }

    &__subtitle {
      color: $neutral4-color;
      font-size: $font-size-medium;
      font-weight: 600;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--selected {
    border-color: $selected-color-sharp;
  }
}
