/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__provider-list__no-results {
  margin-top: 40px;
  text-align: center;
}
.filter-page__provider-list__no-results__text {
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 20px;
}
