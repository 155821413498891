/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__loading-bar {
  position: relative;
  width: 100%;
}
.filter-page__loading-bar__progress {
  position: absolute;
  height: 3px;
  background-color: #59c5b4;
}
.filter-page__loading-bar__progress--success {
  width: 100%;
}
.filter-page__loading-bar__progress--animate {
  animation: animateWidth 2s ease-in;
}
@keyframes animateWidth {
  0% {
    width: 25%;
  }
  100% {
    width: 95%;
  }
}
