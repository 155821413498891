.filter-page__map__mobile {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.filter-page__map__mobile--open-filters {
  display: none;
}
.filter-page__map__mobile__switch-to-filters {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.filter-page__map__mobile__back {
  position: absolute;
  top: 12px;
  left: 12px;
}
