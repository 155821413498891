@import "styles/_variables.scss";

.fencing-card {
  position: relative;
  height: 100%;
  min-height: 300px;
  overflow: hidden;
  border-radius: $default-border-radius;
  background-position: center center;
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(12, 10, 55);
    background: linear-gradient(0deg, rgba(12, 10, 55, 1) 0%, rgba(12, 10, 55, 0.15) 100%);
  }

  &__content {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $space-0 $space-2;
    color: $white;

    &__head {
      margin-bottom: $space-0;
      padding: 0 8px;
      font-size: $font-size-xlarge;
      font-weight: 600;
    }

    &__body {
      margin-bottom: $space-2;
      padding: 0 8px;
      font-size: $font-size-large;
    }

    &__footer {
      &__button {
        width: 100%;
      }
    }
  }
}
