/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__provider-list__pagination {
  margin-top: 30px;
  text-align: center;
}
.filter-page__provider-list__pagination .ant-pagination-item,
.filter-page__provider-list__pagination .ant-pagination-prev,
.filter-page__provider-list__pagination .ant-pagination-next {
  border: 1px solid #d9d9d9;
}
.filter-page__provider-list__pagination .ant-pagination-item:not(.ant-pagination-disabled) a,
.filter-page__provider-list__pagination .ant-pagination-prev:not(.ant-pagination-disabled) a,
.filter-page__provider-list__pagination .ant-pagination-next:not(.ant-pagination-disabled) a {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin: 0;
}
.filter-page__provider-list__pagination .ant-pagination-item:not(.ant-pagination-disabled):hover,
.filter-page__provider-list__pagination .ant-pagination-prev:not(.ant-pagination-disabled):hover,
.filter-page__provider-list__pagination .ant-pagination-next:not(.ant-pagination-disabled):hover {
  border-color: #00aa96;
}
.filter-page__provider-list__pagination .ant-pagination-item:not(.ant-pagination-disabled):hover a,
.filter-page__provider-list__pagination .ant-pagination-prev:not(.ant-pagination-disabled):hover a,
.filter-page__provider-list__pagination .ant-pagination-next:not(.ant-pagination-disabled):hover a {
  color: #00aa96;
}
.filter-page__provider-list__pagination .ant-pagination-item.ant-pagination-disabled,
.filter-page__provider-list__pagination .ant-pagination-prev.ant-pagination-disabled,
.filter-page__provider-list__pagination .ant-pagination-next.ant-pagination-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.filter-page__provider-list__pagination .ant-pagination-item.ant-pagination-item-active {
  border: 0;
  color: #fff;
}
.filter-page__provider-list__pagination .ant-pagination-jump-prev a,
.filter-page__provider-list__pagination .ant-pagination-jump-next a {
  color: #9f9f9f;
}
.filter-page__provider-list__pagination .ant-pagination-jump-prev:hover a,
.filter-page__provider-list__pagination .ant-pagination-jump-next:hover a {
  color: #00aa96;
}
