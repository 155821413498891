@import "styles/_variables.scss";

$skew-height: 28px;

.badge {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 14px;
  background: $attention-color;
  color: $white;
  font-size: $font-size-large;
  font-weight: 600;
  line-height: 1.6;

  &--blue-color {
    background: $accent-2-color;
  }

  &--light-blue-color {
    background: lighten($accent-2-color, 45%);
    color: $accent-2-color;
  }

  &--peacock-color {
    background: $peacock-color;
  }

  &--peacock-sharp-color {
    background: $peacock-sharp-color;
  }

  &--error-color {
    background: lighten($error-color, 35%);
    color: $error-color;
  }

  &--business-color {
    background: $business-color;
  }

  &--success-color {
    background: lighten($success-color, 35%);
    color: $success-color;
  }

  &--attention-color {
    background: lighten($attention-color, 30%);
    color: $attention-color;
  }

  &--commission-free-color {
    border: 1px solid $commission-free-color;
    background: $commission-free-background-color;
    color: $commission-free-color;
  }

  &--frame-contract-color {
    background: lighten($accent-2-color, 45%);
    color: $accent-2-color;
  }

  &--size {
    &-small {
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: $font-size-medium;
    }

    &-x-small {
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: $font-size-small;
    }
  }

  &--skew {
    position: relative;
    height: $skew-height;
    margin-right: $skew-height;
    border-radius: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -$skew-height;
      width: 0;
      height: 0;
      border-top: solid $skew-height $attention-color;
      border-right: solid $skew-height transparent;
      background: transparent;
    }
  }
}
