/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.filter-page__map__button {
  padding: 18px 8px;
  border: 0;
  border-radius: 7px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  color: #404040;
}
