/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.gallery {
  position: relative;
  height: 0;
  padding-bottom: 45.15625%;
  overflow: hidden;
}
.gallery__loader {
  width: 100%;
  max-width: 100%;
}
.gallery__slider__controls {
  display: flex;
  position: absolute;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.gallery__slider__controls__control {
  padding: 0.5em;
  transition: color 0.2s ease-in-out;
  color: rgba(200, 200, 200, 0.8);
  font-size: 2em;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.gallery__slider__controls__control:hover {
  color: #fff;
}
.gallery__slider__slide {
  display: none;
  width: 100%;
  max-width: 100%;
}
.gallery__slider__slide--current {
  display: inline-block;
}
/*! critical:end */
.gallery {
  /* stylelint-disable-line no-duplicate-selectors */
}
.gallery__slider {
  /* stylelint-disable-line no-duplicate-selectors */
}
.gallery__slider__slide {
  /* stylelint-disable-line no-duplicate-selectors */
  display: inline;
  position: absolute;
}
.gallery__slider__slide--prev {
  transform: translateX(-100%);
}
.gallery__slider__slide--prev.gallery__slider__slide--current-to-prev {
  animation: gallery__slider-current-to-prev 0.5s forwards;
}
.gallery__slider__slide--current {
  /* stylelint-disable-line no-duplicate-selectors */
}
.gallery__slider__slide--current.gallery__slider__slide--current-to-prev {
  animation: gallery__slider-next-to-current 0.5s forwards;
}
.gallery__slider__slide--current.gallery__slider__slide--current-to-next {
  animation: gallery__slider-prev-to-current 0.5s forwards;
}
.gallery__slider__slide--next {
  transform: translateX(100%);
}
.gallery__slider__slide--next.gallery__slider__slide--current-to-next {
  animation: gallery__slider-current-to-next 0.5s forwards;
}
@keyframes gallery__slider-current-to-prev {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes gallery__slider-next-to-current {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes gallery__slider-current-to-next {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes gallery__slider-prev-to-current {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
