/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.load_spinner_eventinc {
  display: flex;
  align-items: center;
}
.load_spinner_eventinc .icon {
  display: block;
  margin: 0 auto;
  padding: 15px 35px;
  animation-name: pulse_animation;
  animation-duration: 800ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  font-size: 85px;
  text-align: center;
}
.load_spinner_eventinc--full-height {
  height: 100%;
}
.load_spinner_eventinc--full-page-height {
  height: calc(100vh - 82px);
}
.load_spinner_eventinc--fixed-full-height {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
}
/*! critical:end */
